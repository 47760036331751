.board {
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;

    .row { 
        display: inline-block;

        .board-block {
            display: inline-block;
            width: 2em;
            height: 1.5em;
            font-size: 1.5em;
            line-height: 1.5em;
            margin: 2px 3px;
            padding: 10px 5px;
            text-align: center;
            border-radius: 4px;
            border: 1px solid black;
            outline: none;
            text-transform: uppercase;

            transition: all .3s;

            &--correct {
                background: var(--button-background-correct);
            }

            &--close {
                background: var(--button-background-close);
            }
        }
    }
}