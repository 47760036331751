.keyboard-button {
    position: relative;
    font-size: 1em;
    padding: 10px 12px;
    border: 1px solid var(--button-border);
    color: var(--button-border);
    display: inline-block;
    margin: 3px;
    border-radius: 4px;
    cursor: pointer;
    background: var(--button-background);
    transition: all .3s;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.79);
    

    &:hover {
        background: var(--button-background-hover);
    }

    &--used {
        background: darkgrey;
    }

    &--close {
        background:var(--button-background-close);
        &:hover {
            background: var(--button-background-close-hover);
        }
    }

    &--correct {
        background: var(--button-background-correct);

        &:hover {
            background: var(--button-background-correct-hover)
        }
    }

    .feature {
        position: absolute;
        bottom: 1px;
        left: 1px;
        right: 1px;
        border-bottom: 1px solid white;
        border-radius: 4px;
    }
}