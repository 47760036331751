body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --button-background: #FFF;
  --button-background-hover: #CCC;
  --button-border: #1A1A1A;

  --button-background-close: #ffc966;
  --button-background-close-hover: #ffbd41;

  --button-background-correct: #90ee90;
  --button-background-correct-hover: rgb(121, 240, 121);
}