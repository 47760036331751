.keyboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    flex-wrap: nowrap;
    flex: 1 0 auto;

    .row {
        flex: 1 0 auto;
        flex-wrap: nowrap;
    }
}